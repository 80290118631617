import { GET_USER_BUSINESS_ID } from "../../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../../http-config/trac-http-req";


const state = {
  // walletDetails: null,
};

const getters = {
  // GET_WALLET_DETAILS: (state) => state.walletDetails,
};

const actions = {
 
  FETCH_SETTLEMENT_TYPE: async function (context) {
    const httpReq = {
      url: `/v2/settlements/businesses/${GET_USER_BUSINESS_ID()}/preferences`,
      method: "GET",
      authRequest: true,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    return httpRes;
  },
  UPDATE_SETTLEMENT_TYPE: async function (context, payload) {
    const httpReq = {
      url: `/v1/businesses/settlement/updatetype/${GET_USER_BUSINESS_ID()}`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      // context.commit("SETUP_WALLET_DETAILS", httpRes);
    }

    return httpRes;
  },
  // FETCH_WALLET_DETAILS: async function (context) {
  //   // Always fetching stores
  //   const httpReq = {
  //     url: `/api/EWTraction/accountdetailsbyuserguid/${GET_USER_BUSINESS_ID()}`,
  //     method: "GET",
  //     authRequest: true,
  //   };

  //   // Fetch online
  //   const httpRes = await HTTP_REQUEST(httpReq);

  //   if (httpRes.status) {
  //     context.commit("SETUP_WALLET_DETAILS", httpRes);
  //   }
  // },
  // FETCH_TELCOMS: async function (context) {
  //   // Always fetching stores
  //   const httpReq = {
  //     url: `/api/EWTraction/telcoms`,
  //     method: "GET",
  //     authRequest: true,
  //   };

  //   // Fetch online
  //   const httpRes = await HTTP_REQUEST(httpReq);

  //   if (httpRes.status) {
  //   }
  //   return httpRes;
  // },
  // FETCH_TELCOMS_DATA_INFO: async function (context, payload) {
  //   // Always fetching stores
  //   const httpReq = {
  //     // url: `/api/EWTraction/telcoms`,
  //     url: `/api/EWTraction/mobiledata/${payload.selectedNetwork}`,
  //     method: "GET",
  //     authRequest: true,
  //   };

  //   // Fetch online
  //   const httpRes = await HTTP_REQUEST(httpReq);

  //   if (httpRes.status) {
  //   }
  //   return httpRes;
  // },
  // TRANSFER_TO_BENEFICIARY: async function (context, payload) {
  //   console.log(payload);
  //   // Always fetching stores
  //   const httpReq = {
  //     url: `/api/EWTraction/transferFunds/${payload.wallet.walletid}`,
  //     method: "POST",
  //     authRequest: true,
  //     body: payload.transferDetails,
  //   };

  //   // Fetch online
  //   const httpRes = await HTTP_REQUEST(httpReq);

  //   if (httpRes.status) {

  //   }

  //   return httpRes;
  // },
  // MAKE_AIRTIME_REQUEST: async function (context, payload) {
  //   console.log(payload);
  //   // Always fetching stores
  //   const httpReq = {
  //     url: `/api/EWTraction/product/airtime/${payload.walletDetails.walletid}`,
  //     method: "POST",
  //     authRequest: true,
  //     body: payload,
  //   };

  //   // Fetch online
  //   const httpRes = await HTTP_REQUEST(httpReq);

  //   if (httpRes.status) {

  //   }

  //   return httpRes;
  // },
  // MAKE_DATA_REQUEST: async function (context, payload) {
  //   console.log(payload);
  //   // Always fetching stores
  //   const httpReq = {
  //     url: `/api/EWTraction/product/mobiledata/${virtualNumber}`,
  //     method: "POST",
  //     authRequest: true,
  //     body: payload,
  //   };

  //   // Fetch online
  //   const httpRes = await HTTP_REQUEST(httpReq);

  //   if (httpRes.status) {

  //   }

  //   return httpRes;
  // },
};

const mutations = {
  // SETUP_WALLET_DETAILS: (state, data) => {
  //   state.walletDetails = data;
  // },
  // SETUP_ALL_BENFICIARIES_BANKS: (state, data) => {
  //   state.allBanks = data;
  // },
  // UPDATE_ALL_BENEFICIARIES: (state, data) => {
  //   state.allBeneficiaries.data.push(data);
  // },
  // UPDATE_DELETED_BENEFICIARIES: (state, data) => {
  //   state.allBeneficiaries.data = state.allBeneficiaries.data.filter(beneficiary => beneficiary.beneficiaryGuid !== data.benficiaryID);
  // }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
